import React from "react";
import { useState, useEffect } from "react";
import PricingPlan from "./PricingPlan";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "../../../styles/cssinjs/helpers/variables.js";
import { togglePlan } from "@components/atoms/ToggleButton";

const trackIds = [
  {
    element: "pro-cta-plan-button",
    id: {
      monthly: "pro-pre-footer-monthly",
      annual: "pro-pre-footer-annually",
    },
  },
  {
    element: "team-cta-plan-button",
    id: {
      monthly: "team-pre-footer-monthly",
      annual: "team-pre-footer-annually",
    },
  },
];

export default function PricingPlanWithTitle({
  heading,
  pricing,
  initialPlanType,
  className = "",
  pageName,
}) {
  useEffect(() => {
    window.trackIds = trackIds;
    togglePlan();
  });
  const initialPlanTypeValue = (() => {
    if (initialPlanType === "annual-billing-plan") {
      return "annual-billing-plan";
    } else {
      return "monthly-billing-plan";
    }
  })();
  return (
    <section
      className={`pricing-wrap has-align-top pricing-container ${className}`}
      id={initialPlanTypeValue}
    >
      <article className="text-center mx-auto md:px-3 lg:maxw-xl">
        <h2
          className="text-xl lg:pt-5 lg:mb-3 md:mt-2 md:text-left"
          dangerouslySetInnerHTML={{ __html: heading.heading }}
        />
        <>
          {Array.isArray(heading.desc) ? (
            heading.desc.map((item, index) => {
              return (
                <p
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                  className="lg:mb-3 lg:maxw-lg lg:ml-auto lg:mr-auto md:text-left"
                />
              );
            })
          ) : (
            <p
              className="lg:mb-3 lg:maxw-lg lg:ml-auto lg:mr-auto md:text-left"
              dangerouslySetInnerHTML={{ __html: heading.desc }}
            />
          )}
        </>
      </article>
      <PricingPlan
        toggle={pricing.toggle}
        plans={pricing.plans}
        planInfo={pricing.planInfo}
        trackIds={trackIds}
      />
      <style jsx>{`
        .pricing-wrap .card--default > * {
          position: relative;
          padding: 20px 32px 104px;
          margin-bottom: 20px;
          font-size: ${font.size.sm[3]};
          line-height: ${lineHeight[6]};
        }
        ${pageName == "Pricing Root Page"
          ? ``
          : `
          .pricing-wrap .card--default > * {
              box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2), 0 1px 3px 0 rgba(48, 58, 71, 0.1);          
          }
          `}
        .pricing-wrap .card--default > :hover {
          box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
            0 1px 4px 0 rgba(22, 45, 61, 0.1);
        }
        .pricing-wrap .card--default .plan-cta {
          min-height: 88px;
          position: absolute;
          width: calc(100% - 78px);
          left: 38px;
          bottom: 16px;
        }
        .pricing-wrap .card--default .notice {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: auto;
          white-space: nowrap;
          padding: 4px 8px;
          margin-top: 16px;
          font-size: ${font.size.sm[1]};
          line-height: ${font.size.sm[4]};
          color: ${colors.primitive.green[200]};
          background-color: ${colors.primitive.green[100]};
          border-radius: 40px;
          margin: 16px auto 0;
        }
        .pricing-wrap .card--default .highlight {
          font-size: ${font.size.lg[2]};
          line-height: ${font.size.lg[3]};
          color: ${colors.primitive.black[700]};
        }
        .pricing-wrap .card--default .plan-user {
          margin-bottom: 5px;
        }
        .pricing-wrap .card--default .ticked-bulletin {
          margin-top: 20px;
          margin-bottom: 24px;
        }
        .pricing-wrap .card--default .ticked-bulletin li {
          margin-bottom: 6px;
        }
        .pricing-wrap .card--default .ticked-bulletin li::before {
          margin-right: 8px;
        }
        pricing-wrap .card--default .ticked-bulletin.list-item {
          margin: 0;
        }
        .pricing-wrap .card--default .ticked-bulletin.list-item li {
          margin-bottom: 0;
        }
        .pricing-wrap .card--default .p-block {
          box-shadow: 0 1px 2px 0 rgba(51, 57, 61, 0.2),
            0 1px 4px 0 rgba(51, 57, 61, 0.1);
          padding: 20px;
          width: 100%;
        }
        .pricing-wrap .card--default .p-block > * {
          color: ${colors.primitive.black[100]};
        }
        .pricing-wrap .card--default .p-block li {
          margin-left: 80px;
        }
        .pricing-wrap .card--default .list-item {
          width: 100%;
          margin-bottom: 0;
          padding: 14px 19px 16px 20px;
        }
        .pricing-wrap .card--default .list-item > * {
          font-size: ${font.size.sm[4]};
        }
        @media (max-width: ${viewport.tablet.sm}) {
          .pricing-wrap.card--default > * {
            padding: 20px 40px 104px;
          }
          .pricing-wrap .card--default > :last-child {
            margin-bottom: 0;
          }
          .pricing-wrap .card--default .plan-cta {
            min-height: 40px;
            width: 100%;
            position: unset;
          }
          .pricing-wrap h2 {
            font-size: ${font.size.lg[1]};
            line-height: ${lineHeight[10]};
          }
          .pricing-wrap .card--default.pr-card a.btn-primary,
          .pricing-wrap .card--default.pr-card a.btn-secondary {
            background-color: ${colors.derived.brand};
            color: ${colors.primitive.white.default};
          }
        }
      `}</style>
    </section>
  );
}
