import { ButtonHTMLAttributes, useId, useState } from "react";
import {
  IterateAndAddClass,
  IterateAndRemoveClass,
  IterateAndToggleClass,
} from "@util/utils";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "@styles/cssinjs/helpers/variables.js";

declare global {
  interface Window {
    trackIds?: any[];
  }
}

type announcementObject = {
  onState: string;
  offState: string;
};

type ToggleProps = {
  toggled: boolean;
  onToggleClick: (isToggled: boolean) => void;
  label: string;
  announcement?: announcementObject;
} & Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  | "onClick"
  | "id"
  | "aria-checked"
  | "aria-labelledby"
  | "className"
  | "role"
  | "type"
>;

export const togglePlan = () => {
  const pricingWrapper = document.querySelector(
    ".pricing-container"
  ) as HTMLElement;
  const pricingType = pricingWrapper ? pricingWrapper.id : "";

  const discountedPriceElements = [
    ...document.getElementsByClassName("discount-img"),
  ];
  const displayedPriceElements = [
    ...document.getElementsByClassName("price-wrapper"),
  ];

  const toggleBtn = [...document.getElementsByClassName("g-togg-btn")];

  const AnnualBillingSpans = [
    ...document.getElementsByClassName("annual-billing-span"),
  ];

  const MonthlyBillingSpans = [
    ...document.getElementsByClassName("monthly-billing-span"),
  ];

  if (pricingType === "annual-billing-plan") {
    IterateAndRemoveClass(discountedPriceElements, "active");
    IterateAndRemoveClass(displayedPriceElements, "counter");
    IterateAndToggleClass(toggleBtn, "g-togg--off", "g-togg--on");
    IterateAndRemoveClass(AnnualBillingSpans, "active");
    IterateAndAddClass(MonthlyBillingSpans, "active");
    pricingWrapper.setAttribute("id", "monthly-billing-plan");
    if (window.trackIds) {
      window.trackIds.forEach((trackId) => {
        const trackIdElement = document.querySelector(
          `.${trackId.element}`
        ) as HTMLElement;
        trackIdElement?.setAttribute("id", trackId.id.monthly);
        if (trackId.url) {
          trackIdElement?.setAttribute("href", trackId.url.monthly);
        }
      });
    }
  } else {
    IterateAndAddClass(discountedPriceElements, "active");
    IterateAndAddClass(displayedPriceElements, "counter");
    IterateAndToggleClass(toggleBtn, "g-togg--on", "g-togg--off");
    IterateAndAddClass(AnnualBillingSpans, "active");
    IterateAndRemoveClass(MonthlyBillingSpans, "active");
    if (pricingWrapper) {
      pricingWrapper.setAttribute("id", "annual-billing-plan");
    }

    if (window.trackIds) {
      window.trackIds.forEach((trackId) => {
        const trackIdElement = document.querySelector(
          `.${trackId.element}`
        ) as HTMLElement;
        trackIdElement?.setAttribute("id", trackId.id.annual);
        if (trackId.url) {
          trackIdElement?.setAttribute("href", trackId.url.annual);
        }
      });
    }
  }
};

export function ToggleButton({
  toggled = false,
  label = "toggle switch.",
  disabled = false,
  announcement,
  ...props
}: ToggleProps) {
  const [isChecked, setIschecked] = useState<boolean>(toggled);
  const id = useId();

  const handleClicked = () => {
    setIschecked(!isChecked);
    togglePlan();
  };

  return (
    <button
      aria-checked={isChecked ? "true" : "false"}
      aria-label={label}
      aria-describedby={id}
      className={`g-togg-btn g-togg-btn-lg ${
        toggled ? "g-togg--on" : "g-togg--off"
      }`}
      onClick={handleClicked}
      role="switch"
      type="button"
      {...props}
    >
      <cite></cite>
      <style jsx>{`
        .g-togg-btn {
          width: 38px;
          height: 20px;
          cursor: pointer;
          position: relative;
          padding: 2px;
          border-radius: 15px;
          background-color: ${colors.primitive.grey[1400]};
          border: none;
        }
        .g-togg-btn cite {
          width: 19px;
          height: 16px;
          position: absolute;
          top: 2px;
          left: 2px;
          font-size: ${font.size.sm[1]};
          font-style: normal;
          padding: 6px 7px 5px;
          border-radius: 13px;
          background-color: ${colors.primitive.white.default};
          transition: all 0.3s ease;
          text-align: center;
        }
        .g-togg-btn.g-togg-btn-lg {
          width: 46px;
          height: 26px;
        }
        .g-togg-btn.g-togg-btn-lg cite {
          height: 22px;
          width: 22px;
        }
        .g-togg-btn.g-togg--on {
          background-color: ${colors.primitive.green.light};
        }
        .g-togg-btn.g-togg--on cite {
          left: 21px;
        }
        @media (max-width: ${viewport.tablet.sm}) {
          .g-togg--off {
            background-color: ${colors.primitive.black[300]};
          }
        }
      `}</style>
      {announcement && Object.keys(announcement).length > 0 && (
        <span id={id} className="sr-only">
          {isChecked ? announcement.onState : announcement.offState}
        </span>
      )}
    </button>
  );
}
