import { useRef } from "react";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "../../../styles/cssinjs/helpers/variables.js";

export default function ContactCard({ data, className }) {
  const anchorRef = useRef(null);
  return (
    <section
      className={`${
        className ? className : ""
      } container grid--default has-align-middle br-8 md:pt-3 md:pb-3 md:pl-5 md:pr-5 lg:maxw-xl lg:pl-6 lg:pr-6 lg:pt-3 lg:pb-4`}
    >
      <div className="lg:maxw-xss">
        <article className="fx fx--sb">
          <h2
            className="text-lg lg:mb-5"
            dangerouslySetInnerHTML={{ __html: data.title }}
          ></h2>
          {data.hasOwnProperty("icon") && (
            <figure aria-hidden="true">
              <svg width={data.dimensions[0]} height={data.dimensions[1]}>
                <use href={`/sprite.svg#${data.icon}`}></use>
              </svg>
            </figure>
          )}
        </article>
        {data.hasOwnProperty("button") && (
          <a
            href={data.button.href}
            className="btn-secondary btn-lg w-100 md:mt-3"
          >
            <span>{data.button.text}</span>
          </a>
        )}
        <style jsx>{`
          @media (min-width: ${viewport.tablet.sm}) {
            h2 {
              font-size: ${font.size.lg[2]}!important;
              line-height: ${lineHeight[12]}!important;
            }
          }
          @media (max-width: ${viewport.tablet.sm}) {
            h2 {
              font-size: ${font.size.lg[1]}!important;
              line-height: ${lineHeight[10]}!important;
            }
          }
        `}</style>
      </div>{" "}
      <div className="lg:pl-2.5">
        {data.desc?.map((item, index) => {
          return (
            <p
              className="text-left text-xs lg:mt-1 md:mt-3"
              key={index}
              dangerouslySetInnerHTML={{ __html: item }}
              data-testid={`description-${index}`}
            />
          );
        })}

        {data.hasOwnProperty("link") && (
          <a
            className="text-brandcolor"
            id={data.link.id}
            href={data.link.href}
            ref={anchorRef}
          >
            <svg
              width={data.link.dimensions[0]}
              height={data.link.dimensions[1]}
              className="arrow-right"
            >
              <use href={`/sprite.svg#${data.link.icon}`}></use>
            </svg>
            <span>{data.link.text}</span>
          </a>
        )}
        {data.hasOwnProperty("note") && (
          <p className="lg:mt-5 md:mt-5">{data.note}</p>
        )}
        <style jsx>{`
          a {
            font-size: ${font.size.sm[3]}!important;
            line-height: ${lineHeight[6]}!important;
          }
          .arrow-right {
            margin-right: 8px;
            vertical-align: middle;
          }
          .arrow-right ~ span {
            vertical-align: middle;
          }
        `}</style>
      </div>
    </section>
  );
}
